import moment from 'moment';

export function fISOToReadable(date: Date) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('MMM Do YY, h:mm a');
}

export function fISOToReadableNoTime(date?: string | null) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('MMM Do, YYYY');
}

export function fISOToReadableNoTimeShort(date: string) {
  return moment(date).format('Do MMM YY');
}

export function fISOToReadableMonthYear(date: string) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('MMMM YYYY');
}

export function fISOToCalendarDate(date: string | Date) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('YYYY-MM-DD');
}

export function fISOToYearMonthDay(date: string) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('YYYY/MM/DD');
}

export function fISOToWeekNumber(date: string) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('W');
}

export function fISOToWeekStart(date: string) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).startOf('week').format('MMM Do yy');
}

export function fISOToWeekNumberToDate(year: number, weekNumber: number) {
  if (!weekNumber) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment().year(year).day('Sunday').week(weekNumber).format();
}

export function getOrdinalDate(date: Date) {
  return moment(date).format('Do');
}

export function fISOToReadableDay(date: string) {
  return moment(date).format('ddd, Do MMMM');
}

export function fISOToTime(date: string) {
  return moment(date).format('HH:mm');
}

const dayMapping: { [key: string]: number } = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export function formatDay(day: string, format: string) {
  if (day in dayMapping) {
    const dayIndex = dayMapping[day];
    return moment().day(dayIndex).format(format);
  }

  return moment().day(day).format(format);
}
